import { gql } from "@apollo/client"
import { FeaturedCompanyFragment } from "../Base/FeaturedCompany/fragment"

export const GroupHiringCompaniesFragment = gql`
    fragment GroupHiringCompaniesFragment_group on GroupType {
        id
        name
        featuredHiringCompanies: featuredCompanies(numCompanies: 5) {
            ...FeaturedCompanyFragment_company
        }
    }
    ${FeaturedCompanyFragment}
`

import { gql } from "@apollo/client"
import CoverPhoto from "components/CoverPhoto"
import { ListImageHeader_applylist } from "__generated__/ListImageHeader_applylist"

const ListImageHeaderFragment = gql`
    fragment ListImageHeader_applylist on ApplylistType {
        banner {
            source
        }
        canEdit
        slug
    }
`

type ListImageHeaderProps = {
    applylist: ListImageHeader_applylist
    desktopHeight: string
    mobileHeight: string
}

const ImageHeader = (props: ListImageHeaderProps) => {
    return (
        <CoverPhoto
            src={props.applylist.banner?.source}
            colorSlug=""
            containerHeight={[props.mobileHeight, props.desktopHeight]}
        />
    )
}

ImageHeader.fragment = ListImageHeaderFragment

export default ImageHeader

import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import { Pill, mq, Text, theme } from "@pallet-hq/hegel"
import { InfoWithIcon } from "components/InfoWithIcon"
import { CompanyLink } from "page-components/atoms/AppPages"
import {
    formatJobTooltipText,
    formatJobTooltipText2,
} from "page-components/job/PostInfo/utils"
import { IoEllipsisHorizontal } from "react-icons/io5"
import CircleImagePreview from "../Image/CircleImagePreview"
import { PostJobSuccess_post } from "__generated__/PostJobSuccess_post"

const PostJobSuccessFragment = gql`
    fragment PostJobSuccess_post on PostType {
        id
        uuid
        jobTitle
        isFeatured(applylist: $applylist)
        company {
            id
            name
            website
            ...CompanyImagePreview_company
        }
        locations {
            id
            shortLabel
        }
        isOpenToRemote
        experience {
            id
            shortName
        }
        workType {
            id
            name
        }
        salary
        equity
    }
    ${CircleImagePreview.companyFragment}
`

type PostJobItemSuccessProps = {
    post: PostJobSuccess_post
}

const PostJobItemSuccess = ({
    post: {
        jobTitle,
        isFeatured,
        company,
        locations,
        isOpenToRemote,
        experience,
        workType,
        salary,
        equity,
    },
}: PostJobItemSuccessProps) => {
    const location = formatJobTooltipText2(locations)
    const isRemote = isOpenToRemote
    const locationText = location
        ? `${location.text}${isRemote ? " · Remote" : ""}`
        : `${isRemote ? "Remote" : ""}`

    const experiences = formatJobTooltipText(experience)

    return (
        <PostJobSuccess>
            <PostJobItemInfo>
                <CompanyPreview>
                    <CircleImagePreview {...company} width={28} height={28} />
                    <CompanyLink href={company.website}>
                        <Text variant="paragraphMedium" ml="xsmall">
                            {company.name}
                        </Text>
                    </CompanyLink>
                </CompanyPreview>
                <JobInfoDiv>
                    <JobInfoHeader>
                        <Text variant="uiLarge600" mt="xsmall">
                            {jobTitle}
                        </Text>
                        {isFeatured && (
                            <FeaturedPill backgroundColor="black" color="white">
                                Featured
                            </FeaturedPill>
                        )}
                    </JobInfoHeader>
                    {(locationText || experiences) && (
                        <ExperienceAndLocation>
                            {experiences && (
                                <InfoWithIcon
                                    text={`${experiences.text}${
                                        workType ? " · " + workType.name : ""
                                    }`}
                                    tooltip={experiences.tooltip}
                                />
                            )}
                            {locationText && (
                                <InfoWithIcon
                                    text={locationText}
                                    tooltip={location?.tooltip || ""}
                                />
                            )}
                        </ExperienceAndLocation>
                    )}
                    {(salary || equity) && (
                        <SalaryAndEquity>
                            {salary && (
                                <Text variant="paragraphMedium" mr="small">
                                    {salary}
                                </Text>
                            )}
                            {equity && (
                                <Text variant="paragraphMedium" mr="small">
                                    {equity}
                                </Text>
                            )}
                        </SalaryAndEquity>
                    )}
                </JobInfoDiv>
            </PostJobItemInfo>
            <IoEllipsisHorizontal color={theme.colors.gray400} />
        </PostJobSuccess>
    )
}

PostJobItemSuccess.fragment = PostJobSuccessFragment

export default PostJobItemSuccess

const PostJobSuccess = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
`

const PostJobItemInfo = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
`

const CompanyPreview = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const JobInfoHeader = styled.div`
    display: flex;
    flex-direction: row;
`

const JobInfoDiv = styled.div`
    ${mq({ marginTop: [theme.space.small, "0"] })};
    ${mq({ alignSelf: ["", "center"] })};
    width: 100%;
`

const FeaturedPill = styled(Pill)`
    margin-left: auto;
`

const ExperienceAndLocation = styled.div`
    display: flex;
    flex-direction: column;
    > * {
        margin-top: ${theme.space.xsmall};
    }
`

const SalaryAndEquity = styled.div`
    display: flex;
    margin-top: ${theme.space.xsmall};
`

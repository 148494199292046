import { gql } from "@apollo/client"

export const PalletThumbnailImageFragment = gql`
    fragment PalletThumbnailImageFragment on ApplylistType {
        id
        logo {
            source
        }
        slug
    }
`

import { GroupHiringCompaniesFragment_group } from "__generated__/GroupHiringCompaniesFragment_group"
import Base from "../Base"

const HiringCompanies = ({
    group,
    numCompanies,
}: {
    group: GroupHiringCompaniesFragment_group
    numCompanies?: number
}) => {
    return (
        <Base
            headline={`Companies hiring from ${group.name}`}
            featuredCompanies={group.featuredHiringCompanies}
            numCompanies={numCompanies}
        />
    )
}

export default HiringCompanies

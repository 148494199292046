import { gql } from "@apollo/client"
import { notify } from "@pallet-hq/hegel"
import { ListingCreateOrUpdate_error_userError } from "__generated__/ListingCreateOrUpdate_error"

export const ListingCreateOrUpdateErrorFragment = gql`
    fragment ListingCreateOrUpdate_error on ListingCreateOrUpdate {
        userError {
            ... on UserErrorType {
                message
            }
            ... on ExceededActiveJobPostLimitErrorType {
                message
                palletName
            }
        }
    }
`

export const notifyListingCreateOrUpdateErrors = (
    userError?: ListingCreateOrUpdate_error_userError | null,
    palletName?: string
) => {
    if (userError?.__typename === "ExceededActiveJobPostLimitErrorType") {
        const identifier = userError?.palletName
            ? `on ${userError.palletName} `
            : ""
        return notify.fail(
            `You’re out of active job post slots ${identifier}- upgrade your subscription to enable more roles.`
        )
    }

    if (palletName)
        return notify.fail(
            `Failed to list this job on ${palletName}. Please try again or reach out for support.`
        )

    return notify.fail(
        `Failed to list this job. Please try again or reach out for support.`
    )
}

import ThumbnailImage from "components/Image/ThumbnailImage"
import { PalletThumbnailImageFragment } from "__generated__/PalletThumbnailImageFragment"
import { PalletThumbnailImageLoader } from "./Loader"

export const PalletThumbnailImage = ({
    applylist,
}: {
    applylist?: PalletThumbnailImageFragment
}) => {
    return (
        <>
            {applylist ? (
                <ThumbnailImage
                    src={applylist!.logo?.source}
                    colorSlug={applylist!.slug}
                    sizes={["140px", "170px"]}
                />
            ) : (
                <PalletThumbnailImageLoader sizes={["140px", "170px"]} />
            )}
        </>
    )
}

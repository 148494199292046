import styled from "@emotion/styled"
import { Text, theme } from "@pallet-hq/hegel"

export const TextBox = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-radius: 4px;
    padding: 6px ${theme.space.small};
    border: 1px solid ${theme.colors.gray400};
    background: ${theme.colors.gray100};
`

export const SubSectionHeader = styled(Text)`
    margin-bottom: ${theme.space.xsmall};
`

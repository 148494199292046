import { gql } from "@apollo/client"

export const CandidateUpsellModalFragment = gql`
    fragment CandidateUpsellModalFragment_applylist on ApplylistType {
        id
        slug
        canEdit
        group {
            id
            activeMember {
                id
                applicationStatusEnum
            }
        }
    }
`

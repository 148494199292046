import styled from "@emotion/styled"
import { Text, theme } from "@pallet-hq/hegel"
import { FeaturedCompanyFragment_company } from "__generated__/FeaturedCompanyFragment_company"
import FeaturedCompany from "./FeaturedCompany"

const Base = ({
    headline,
    featuredCompanies,
    numCompanies = 5,
}: {
    headline: string
    featuredCompanies: (FeaturedCompanyFragment_company | null)[] | null
    numCompanies?: number
}) => {
    if (Number(featuredCompanies?.length) <= 1) return null
    return (
        <Wrapper>
            <Divider />
            <Text
                variant="uiLarge600"
                textAlign="center"
                mt="medium"
                mb="small"
            >
                {headline}
            </Text>
            <Companies>
                {featuredCompanies
                    ?.slice(0, numCompanies)
                    .map(
                        (company, idx) =>
                            company && (
                                <FeaturedCompany
                                    key={`FeaturedCompany_${idx}`}
                                    company={company}
                                />
                            )
                    )}
            </Companies>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    width: 100%;
    max-width: 1062px;
    margin: 0 auto ${theme.space.large};
    padding: 0 ${theme.space.small};
    box-sizing: border-box;
`

const Divider = styled.div`
    height: 1px;
    background: ${theme.colors.gray200};
`

const Companies = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    & > * {
        margin: ${theme.space.small} ${theme.space.large};
    }
`

export default Base

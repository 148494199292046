import { gql, useMutation } from "@apollo/client"
import useAuthContext from "state/useAuthContext"
import { ListingCreateOrUpdateMutation } from "__generated__/ListingCreateOrUpdateMutation"
import { EDIT_JOB_POST_LISTING_FRAGMENT } from "../fragments"
import { ListingCreateOrUpdateErrorFragment } from "@pallet/features/ListingType/utils/errors"

export const LISTING_CREATE_OR_UPDATE_MUTATION = gql`
    mutation ListingCreateOrUpdateMutation(
        $recruitingTeamUuid: String
        $applylistSlug: String
        $postUuid: String
        $promptAnswer: String
        $enabled: Boolean
    ) {
        listingCreateOrUpdate(
            recruitingTeamUuid: $recruitingTeamUuid
            applylistSlug: $applylistSlug
            postUuid: $postUuid
            promptAnswer: $promptAnswer
            enabled: $enabled
        ) {
            listing {
                id
                partnership {
                    id
                    approvalStatusEnum
                }
                ...EditJobPostListing_listing
            }
            ...ListingCreateOrUpdate_error
        }
    }
    ${EDIT_JOB_POST_LISTING_FRAGMENT}
    ${ListingCreateOrUpdateErrorFragment}
`

export const useCreateOrUpdateListingMutation = () => {
    const { user } = useAuthContext()
    return useMutation<ListingCreateOrUpdateMutation>(
        LISTING_CREATE_OR_UPDATE_MUTATION,
        {
            variables: {
                recruitingTeamUuid: user?.currentTeam?.uuid,
            },
        }
    )
}

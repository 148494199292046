import ImageHeader from "components/Image/ImageHeader"
import { ListImageHeader_applylist } from "__generated__/ListImageHeader_applylist"
import { PalletImageHeaderLoader } from "./Loader"

const PalletImageHeader = ({
    applylist,
}: {
    applylist?: ListImageHeader_applylist
}) => {
    return (
        <>
            {applylist ? (
                <ImageHeader
                    applylist={applylist}
                    desktopHeight="200px"
                    mobileHeight="140px"
                />
            ) : (
                <PalletImageHeaderLoader
                    desktopHeight="200px"
                    mobileHeight="140px"
                />
            )}
        </>
    )
}

PalletImageHeader.fragment = ImageHeader.fragment

export default PalletImageHeader

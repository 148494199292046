import constate from "constate"
import queryString from "query-string"
import { useState } from "react"
import useAuthContext from "./useAuthContext"
import useLoginModal from "./useLoginModal"

const [JobPostModalProvider, useJobPostModal] = constate(() => {
    const { authed, loading } = useAuthContext()
    const { setReturnUrl } = useLoginModal()

    const [isVisible, setVisibility] = useState(false)

    const hideJobPostModal = () => setVisibility(false)

    const showJobPostModal = () => {
        if (loading) return
        setVisibility(true)
        if (!authed) {
            const returnUrl = queryString.stringifyUrl({
                url: window.location.href,
                query: { create: true },
            })
            setReturnUrl(returnUrl)
        }
    }

    return {
        isJobPostModalVisible: isVisible,
        showJobPostModal,
        hideJobPostModal,
    }
})

export { JobPostModalProvider }

export default useJobPostModal

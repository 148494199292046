import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import { mq, Text, theme } from "@pallet-hq/hegel"
import { LinkParser } from "components/Link/LinkParser"
import ReadMore from "components/ReadMore"
import { useState } from "react"
import { ListOverviewCard_applylist } from "__generated__/ListOverviewCard_applylist"
import { useScreenSize } from "utils/hooks/useSize"
import CuratorPreview from "./CuratorPreview"
import Sidecard from "./Sidecard"

const ListOverviewCardFragment = gql`
    fragment ListOverviewCard_applylist on ApplylistType {
        canView
        canPost
        description
        ...CuratorPreview_applylist
    }
    ${CuratorPreview.fragment}
`

type ListOverviewCardProps = {
    applylist?: ListOverviewCard_applylist
}

const ListOverviewCard = (props: ListOverviewCardProps) => {
    const { isMobile } = useScreenSize()
    const descriptionVariant = isMobile ? "paragraphMedium" : "paragraphLarge"
    const [isMinimized, setIsMinimized] = useState(true)

    return (
        <>
            <Sidecard>
                <CuratorPreview {...props} />
                {props.applylist ? (
                    <>
                        <Text variant="headlineSmall">Overview</Text>
                        <DescriptionDiv minimized={isMinimized}>
                            <Text variant={descriptionVariant}>
                                <LinkParser variant={descriptionVariant}>
                                    {props.applylist.description}
                                </LinkParser>
                            </Text>
                        </DescriptionDiv>
                    </>
                ) : null}
                {isMobile && (
                    <ReadMore
                        isMinimized={isMinimized}
                        setIsMinimized={setIsMinimized}
                    />
                )}
            </Sidecard>
        </>
    )
}

ListOverviewCard.fragment = ListOverviewCardFragment

const DescriptionDiv = styled.div`
    overflow-wrap: anywhere;
    word-break: break-word;
    white-space: pre-wrap;
    margin-top: ${theme.space.xsmall};
    ${mq({ overflow: ["hidden", "unset"] })}
    ${mq({ textOverflow: ["ellipsis", "unset"] })}
    ${mq({ display: ["-webkit-box", "unset"] })}
    ${({ minimized }: { minimized: boolean }) =>
        mq({ WebkitLineClamp: [minimized ? "2" : "unset", "unset"] })}
    ${mq({ WebkitBoxOrient: ["vertical", "unset"] })}
`

export default ListOverviewCard

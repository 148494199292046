import { theme } from "@pallet-hq/hegel"
import ContentLoader from "react-content-loader"

export const PostJobButtonLoader = () => {
    return (
        <ContentLoader
            speed={2}
            width="150px"
            height="48px"
            backgroundColor={theme.colors.gray300}
            foregroundOpacity={0.25}
            stroke="none"
            display="block"
        >
            <rect x="0" y="0" rx="8px" ry="8px" width="108px" height="48px" />
        </ContentLoader>
    )
}

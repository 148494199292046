import styled from "@emotion/styled"
import { Text, theme } from "@pallet-hq/hegel"
import { FiChevronDown } from "react-icons/fi"

// generic component to toggle a minimization state
export type ReadMoreProps = {
    isMinimized: boolean
    setIsMinimized: (a: boolean | ((b: boolean) => boolean)) => any
}

const ReadMore = (props: ReadMoreProps) => {
    const toggleMinimized = () => props.setIsMinimized(prevState => !prevState)

    return (
        <ReadMoreDiv onClick={toggleMinimized}>
            <div>
                <Text variant="uiSmall600">
                    {props.isMinimized ? "Read more" : "Minimize"}
                </Text>
            </div>
            <StyledChevronDown open={!props.isMinimized} />
        </ReadMoreDiv>
    )
}

const ReadMoreDiv = styled.div`
    margin-top: ${theme.space.small};
    padding: ${theme.space.xsmall};
    margin-left: calc(${theme.space.xsmall} * -1);
    display: flex;
    align-items: center;
    cursor: pointer;
`

const StyledChevronDown = styled(FiChevronDown)`
    cursor: pointer;
    margin-left: ${theme.space.xsmall};
    transform: ${({ open }: { open: boolean }) => open && "rotate(180deg)"};
`

export default ReadMore

import styled from "@emotion/styled"
import Image from "components/Image"
import OptionalLink from "components/Link/OptionalLink"
import { FeaturedCompanyFragment_company } from "__generated__/FeaturedCompanyFragment_company"

const FeaturedCompany = ({
    company,
}: {
    company: FeaturedCompanyFragment_company
}) => {
    return (
        <OptionalLink
            key={company.id}
            href={company.website}
            title={company.name}
            target="_blank"
            rel="noreferrer"
        >
            <LogoImage
                src={company.image}
                alt={company.name}
                colorSlug=""
                width={64}
                height={64}
            />
        </OptionalLink>
    )
}

const LogoImage = styled(Image)`
    object-fit: contain;
    border-radius: 100%;
`

export default FeaturedCompany

import { gql, useQuery } from "@apollo/client"
import styled from "@emotion/styled"
import { Button, Modal, mq, Text, theme } from "@pallet-hq/hegel"
import { useRouter } from "next/router"
import Confetti from "react-confetti"
import useAuthContext from "state/useAuthContext"
import { usePalletContext } from "utils/hooks/usePalletContext"
import removeQueryString from "utils/removeQueryString"
import { parseBooleans } from "utils/router"
import { PostPreviewQuery } from "__generated__/PostPreviewQuery"
import PostJobItemSuccess from "components/JobItem/PostJobItemSuccess"

const colors = [
    "#ed7474",
    "#f3a683",
    "#f7d794",
    "#fbfafa",
    "#f5f0ef",
    "#e7dad8",
    "#b28d89",
    "#989898",
    "#dcdde1",
    "#333333",
    "#ed7474",
    "#f3a683",
    "#f7d794",
    "#fbfafa",
    "#f5f0ef",
    "#e7dad8",
    "#b28d89",
]

const POST_QUERY = gql`
    query PostPreviewQuery($uuid: UUID!, $applylist: String!) {
        post(uuid: $uuid) {
            id
            listingUuid(applylist: $applylist)
            ...PostJobSuccess_post
        }
        applylist(slug: $applylist) {
            ... on ApplylistType {
                id
                name
            }
        }
    }
    ${PostJobItemSuccess.fragment}
`

type SuccessfulPostModalProps = {
    slug: string
}

const SuccessfulPostModal = (props: SuccessfulPostModalProps) => {
    const router = useRouter()
    const palletContext = usePalletContext()
    const { user } = useAuthContext()
    const { success, uuid, isRepost } = parseBooleans(router.query)

    const { loading, error, data } = useQuery<PostPreviewQuery>(POST_QUERY, {
        variables: { uuid, applylist: props.slug },
        ssr: false,
    })

    const hideModal = () => {
        removeQueryString(router, ["success", "uuid", "isRepost"])
    }

    if (loading) return null
    if (error || data?.post === null) hideModal()

    return (
        <Modal
            title={isRepost ? "Your job has been reposted" : "Post submitted!"}
            isOpen={!!success && !!uuid}
            hideModal={hideModal}
            width="560px"
            showIcon={false}
            actions={
                <Button size="medium" onClick={hideModal}>
                    Continue to job board {"-->"}
                </Button>
            }
        >
            <SuccessfulPostModalDiv>
                <Confetti recycle={false} colors={colors} width={560} />
                {data && data.post && (
                    <PreviewJobDiv
                        onClick={() => {
                            window.open(
                                (palletContext.pallet !== null
                                    ? `${window.origin}/jobs/${
                                          data!.post!.listingUuid
                                      }`
                                    : `${window.origin}/list/${
                                          props.slug
                                      }/jobs/${data!.post!.listingUuid}`) +
                                    (palletContext.usingFallback
                                        ? `?pallet=${palletContext.pallet}`
                                        : "")
                            )
                        }}
                    >
                        <PostJobItemSuccess post={data.post} />
                    </PreviewJobDiv>
                )}
                <br />
                {isRepost ? (
                    <PaddedSubtitle variant="headlineSmall">
                        This role has been approved and is live again on{" "}
                        {data?.applylist?.name}
                    </PaddedSubtitle>
                ) : (
                    <PaddedSubtitle variant="headlineMedium">
                        We’ll contact you at <b>{user?.email}</b> about the
                        status of your post.
                        <Disclaimer variant="paragraphMedium">
                            It may take up to 7 days for your posting to be
                            approved.
                        </Disclaimer>
                    </PaddedSubtitle>
                )}
            </SuccessfulPostModalDiv>
        </Modal>
    )
}

const SuccessfulPostModalDiv = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    > canvas {
        width: 100%;
        height: 100%;
    }
`

const PaddedSubtitle = styled(Text)`
    padding: ${theme.space.xxsmall} 0 0;
`

const Disclaimer = styled(Text)`
    padding: ${theme.space.small} 0 0;
`

const PreviewJobDiv = styled.div`
    border: 1px solid ${theme.colors.gray400};
    border-radius: 10px;
    padding: ${theme.space.medium};
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mq({ alignItems: ["flex-start", "center"] })};
`

export default SuccessfulPostModal

import { Button } from "@pallet-hq/hegel"
import { VerticallyCenteredRow } from "components/atoms"
import { onShareClick } from "components/Buttons/ShareButton"
import {
    SubSectionHeader,
    TextBox,
} from "components/Modals/SharePalletModal/shared"

const CopyLinkShare = () => {
    const applylistLink = window.location.href

    return (
        <div>
            <SubSectionHeader variant="uiLarge600">
                Share via link
            </SubSectionHeader>
            <VerticallyCenteredRow>
                <TextBox>{applylistLink}</TextBox>
                <Button
                    variant="secondary"
                    size="small"
                    ml={2}
                    onClick={() => onShareClick(applylistLink)}
                >
                    Copy
                </Button>
            </VerticallyCenteredRow>
        </div>
    )
}

export default CopyLinkShare

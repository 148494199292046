import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import { theme } from "@pallet-hq/hegel"
import { VerticallyCenteredRow } from "components/atoms"
import { ReactComponent as TikTokIcon } from "components/icons/logos/tiktok-outline.svg"
import { NewTabLink } from "components/Link/NewTabLink"
import {
    FiFacebook,
    FiGlobe,
    FiInstagram,
    FiLinkedin,
    FiTwitch,
    FiTwitter,
    FiYoutube,
} from "react-icons/fi"
import { CuratorSocials_applylist } from "__generated__/CuratorSocials_applylist"

const CuratorSocialsFragment = gql`
    fragment CuratorSocials_applylist on ApplylistType {
        curatorFacebookUrl
        curatorInstagramUrl
        curatorLinkedinUrl
        curatorTwitchUrl
        curatorTwitterUrl
        curatorTiktokUrl
        curatorWebsiteUrl
        curatorYoutubeUrl
    }
`
type CuratorSocialsProps = {
    applylist?: CuratorSocials_applylist
}

const CuratorSocials = (props: CuratorSocialsProps) => {
    const links = [
        { icon: FiGlobe, link: props.applylist?.curatorWebsiteUrl },
        { icon: FiTwitter, link: props.applylist?.curatorTwitterUrl },
        { icon: FiInstagram, link: props.applylist?.curatorInstagramUrl },
        { icon: FiFacebook, link: props.applylist?.curatorFacebookUrl },
        { icon: TikTokIcon, link: props.applylist?.curatorTiktokUrl },
        { icon: FiTwitch, link: props.applylist?.curatorTwitchUrl },
        { icon: FiYoutube, link: props.applylist?.curatorYoutubeUrl },
        { icon: FiLinkedin, link: props.applylist?.curatorLinkedinUrl },
    ]
    return links.filter(social => social.link).length > 0 ? (
        <SpacedSocials>
            {links.map((social, i) =>
                social.link ? (
                    <StyledNewTabLink key={i} href={social.link}>
                        <social.icon size={24} />
                    </StyledNewTabLink>
                ) : null
            )}
        </SpacedSocials>
    ) : null
}

CuratorSocials.fragment = CuratorSocialsFragment

export default CuratorSocials

const StyledNewTabLink = styled(NewTabLink)`
    :hover {
        background-color: ${theme.colors.gray100};
    }
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`

const SpacedSocials = styled(VerticallyCenteredRow)`
    & > :not(:last-child) {
        margin-right: ${theme.space.xxsmall};
    }
`

import { gql, useQuery, useMutation } from "@apollo/client"
import styled from "@emotion/styled"
import mixpanel from "mixpanel-browser"
import { EVENTS } from "constants/events"
import { Button, mq, theme } from "@pallet-hq/hegel"
import { ReactNode } from "react"
import { DesktopOnly, MobileOnly } from "components/atoms"
import PostJobButton from "components/Buttons/PostJob"
import CandidateUpsellModal from "components/Modals/CandidateUpsellModal"
import { CandidateUpsellFragment } from "components/Modals/CandidateUpsellModal/fragment"
import { Modal as CandidateApplicationModal } from "@pallet/features/onboardingL2/Modal"
import { CandidateApplicationProvider } from "@pallet/features/onboardingL2/useCandidateApplication"
import { NavigationControlsProvider } from "@pallet/features/onboardingL2/useNavigationControls"
import ListInfo from "page-components/list/ListInfo"
import ListOverviewCard from "page-components/list/ListOverviewCard"
import { CandidateUpsellModalProvider } from "state/useCandidateUpsellModal"
import { CandidateUpsellModalFragment } from "state/useCandidateUpsellModal/fragment"
import { JobPostEditorModalProvider } from "state/useJobPostEditorModal"
import { JobPostModalProvider } from "state/useJobPostModal"
import {
    JobsLayoutQuery,
    JobsLayoutQuery_applylist,
} from "__generated__/JobsLayoutQuery"
import PalletImageHeader from "./PalletImageHeader"
import PostResponse from "./PostResponse"
import { PalletThumbnailImage } from "./PalletThumbnailImage"
import { PalletThumbnailImageFragment } from "./PalletThumbnailImage/fragment"
import { usePalletContext } from "utils/hooks/usePalletContext"
import useAuthContext from "state/useAuthContext"
import { usePalletRouter } from "utils/hooks/usePalletRouter"
import { ROUTES } from "constants/routes"
import { UNSUBSCRIBE_FROM_PALLET } from "mutations"
import { UnsubscribeFromPallet } from "__generated__/UnsubscribeFromPallet"

const PALLET_JOBS_LAYOUT_QUERY = gql`
    query JobsLayoutQuery($slug: String!) {
        applylist(slug: $slug) {
            ... on ApplylistType {
                id
                slug
                name
                canEdit
                postCount
                reviewCount
                notifyOnUpdate
                managementSubscriptionsCount
                group {
                    id
                }
                ...ListImageHeader_applylist
                ...PalletThumbnailImageFragment
                ...ListInfo_applylist
                ...ListOverviewCard_applylist
                ...PostJobButton_applylist
                ...CandidateUpsellFragment_applylist
                ...CandidateUpsellModalFragment_applylist
            }
        }
    }

    ${PalletImageHeader.fragment}
    ${PalletThumbnailImageFragment}
    ${ListInfo.fragment}
    ${ListOverviewCard.fragment}
    ${CandidateUpsellFragment}
    ${CandidateUpsellModalFragment}
    ${PostJobButton.fragment}
`

type JobsLayoutProps = {
    isPrivate?: boolean
    children?: ReactNode
}

const JobsLayout = ({ isPrivate = false, children }: JobsLayoutProps) => {
    const { authed } = useAuthContext()
    const router = usePalletRouter()
    const { pallet } = usePalletContext()
    const slug = pallet!
    const { data } = useQuery<JobsLayoutQuery>(PALLET_JOBS_LAYOUT_QUERY, {
        variables: { slug },
    })
    const [unsubscribeFromPallet] = useMutation<UnsubscribeFromPallet>(
        UNSUBSCRIBE_FROM_PALLET
    )

    if (data?.applylist === null) return null

    const hasValidPermissions = !isPrivate || data?.applylist.canEdit

    return (
        <Providers applylist={data?.applylist} slug={slug}>
            <PostResponse slug={slug} />
            <PalletImageHeader applylist={data?.applylist} />
            <PageDiv>
                <PageLeftDiv>
                    <PalletThumbnailImage applylist={data?.applylist} />
                    <ListInfo applylist={data?.applylist} />
                    <MobileOverviewDiv>
                        <ListOverviewCard applylist={data?.applylist} />
                        {data?.applylist.canView && (
                            <Button
                                variant={
                                    data.applylist.notifyOnUpdate
                                        ? "secondary"
                                        : "primary"
                                }
                                width={1}
                                my={2}
                                onClick={async () => {
                                    if (data.applylist?.notifyOnUpdate) {
                                        mixpanel.track(
                                            EVENTS.JOBS_CLICK_UNFOLLOW,
                                            { pallet: pallet }
                                        )
                                        unsubscribeFromPallet({
                                            variables: {
                                                slug: pallet,
                                            },
                                        })
                                    } else {
                                        mixpanel.track(
                                            EVENTS.JOBS_CLICK_FOLLOW,
                                            { pallet: pallet }
                                        )
                                        if (!authed) {
                                            await router.push(
                                                ROUTES.ONBOARDING_SIGNUP
                                            )
                                        } else {
                                            await router.push(
                                                ROUTES.ONBOARDING_PREFERENCES
                                            )
                                        }
                                    }
                                }}
                            >
                                {data.applylist.notifyOnUpdate
                                    ? "Following"
                                    : "Follow"}
                            </Button>
                        )}
                    </MobileOverviewDiv>
                    {hasValidPermissions ? <div>{children}</div> : null}
                </PageLeftDiv>
                <PageRightDiv>
                    <ListOverviewCard applylist={data?.applylist} />
                    {data?.applylist.canView && (
                        <Button
                            variant={
                                data.applylist.notifyOnUpdate
                                    ? "secondary"
                                    : "primary"
                            }
                            width={1}
                            my={2}
                            onClick={async () => {
                                if (data.applylist?.notifyOnUpdate) {
                                    mixpanel.track(EVENTS.JOBS_CLICK_UNFOLLOW, {
                                        pallet: pallet,
                                    })
                                    unsubscribeFromPallet({
                                        variables: {
                                            slug: pallet,
                                        },
                                    })
                                } else {
                                    mixpanel.track(EVENTS.JOBS_CLICK_FOLLOW, {
                                        pallet: pallet,
                                    })
                                    if (!authed) {
                                        await router.push(
                                            ROUTES.ONBOARDING_SIGNUP
                                        )
                                    } else {
                                        await router.push(
                                            ROUTES.ONBOARDING_PREFERENCES
                                        )
                                    }
                                }
                            }}
                        >
                            {data.applylist.notifyOnUpdate
                                ? "Following"
                                : "Follow"}
                        </Button>
                    )}
                </PageRightDiv>
            </PageDiv>
            {data?.applylist && (
                <>
                    <CandidateUpsellModal applylist={data.applylist} />
                    <CandidateApplicationModal />
                </>
            )}
        </Providers>
    )
}

const Providers = (props: {
    slug: string
    applylist?: JobsLayoutQuery_applylist
    children: ReactNode
}) => (
    <JobPostModalProvider>
        <JobPostEditorModalProvider>
            <CandidateUpsellModalProvider applylist={props.applylist}>
                <CandidateApplicationProvider>
                    <NavigationControlsProvider>
                        {props.children}
                    </NavigationControlsProvider>
                </CandidateApplicationProvider>
            </CandidateUpsellModalProvider>
        </JobPostEditorModalProvider>
    </JobPostModalProvider>
)

JobsLayout.query = PALLET_JOBS_LAYOUT_QUERY

const MobileOverviewDiv = styled(MobileOnly)`
    align-self: center;
    width: 100%;
`

const PageDiv = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 auto;
    ${mq({ marginTop: ["-96px", "-112px"] })}
`

const PageLeftDiv = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 1;
    max-width: 860px;
    flex-direction: column;
    ${mq({
        padding: [`0 ${theme.space.small}`, `0 0 0 ${theme.space.large}`],
    })}
`

const PageRightDiv = styled(DesktopOnly)`
    margin: 0 ${theme.space.medium};

    /* sticky */
    align-self: flex-start;
    position: sticky;
    top: 160px;
`

export default JobsLayout

import { MODAL_TYPES, TRACK_EVENTS } from "constants/events"
import constate from "constate"
import mixpanel from "mixpanel-browser"
import { useState } from "react"
import { CandidateUpsellModalFragment_applylist } from "__generated__/CandidateUpsellModalFragment_applylist"
import { ApplicationStatusEnum } from "__generated__/globalTypes"
import useSubscribeModal from "../useSubscribeModal"

const SESSION_KEY = "autoShowCandidateModal"

const [CandidateUpsellModalProvider, useCandidateUpsellModal] = constate(
    ({ applylist }: { applylist?: CandidateUpsellModalFragment_applylist }) => {
        const [isOpen, setIsOpen] = useState(false)
        const { autoShowSubscribeModal } = useSubscribeModal()
        const closeModal = () => setIsOpen(false)

        const autoShowModal = () => {
            if (!applylist || applylist.canEdit) return
            if (applylist.group) {
                if (
                    applylist.group.activeMember &&
                    applylist.group.activeMember.applicationStatusEnum !==
                        ApplicationStatusEnum.INCOMPLETE &&
                    applylist.group.activeMember.applicationStatusEnum !==
                        ApplicationStatusEnum.NOT_STARTED
                ) {
                    autoShowSubscribeModal()
                } else if (
                    !window?.sessionStorage?.getItem(
                        `${SESSION_KEY}_${applylist.slug}`
                    )
                ) {
                    window?.sessionStorage?.setItem(
                        `${SESSION_KEY}_${applylist.slug}`,
                        "true"
                    )
                    mixpanel.track(TRACK_EVENTS.MODAL_VIEWED, {
                        type: MODAL_TYPES.JOB_BOARD_CANDIDATE_UPSELL,
                        applylist_slug: applylist.slug,
                        is_auto_prompt: true,
                    })
                    setIsOpen(true)
                }
            } else autoShowSubscribeModal()
        }

        return { isOpen, closeModal, autoShowModal }
    }
)

export { CandidateUpsellModalProvider }
export default useCandidateUpsellModal

import styled from "@emotion/styled"
import { Box, Button, FullPageModal, Text } from "@pallet-hq/hegel"
import ThumbnailImage from "components/Image/ThumbnailImage"
import { useCandidateApplication } from "@pallet/features/onboardingL2/useCandidateApplication"
import HiringCompanies from "features/companies/featured/display/HiringCompanies"
import useCandidateUpsellModal from "state/useCandidateUpsellModal"
import { CandidateUpsellFragment_applylist } from "__generated__/CandidateUpsellFragment_applylist"
import { useScreenSize } from "utils/hooks/useSize"

const CandidateUpsellModal = ({
    applylist,
}: {
    applylist: CandidateUpsellFragment_applylist
}) => {
    const { isMobile } = useScreenSize()
    const { isOpen, closeModal } = useCandidateUpsellModal()
    const { showModal } = useCandidateApplication()
    if (!applylist.group) return null

    return (
        <FullPageModal isOpen={isOpen} hideModal={closeModal}>
            <Wrapper>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    margin="auto"
                >
                    <ThumbnailImage
                        src={applylist.logo?.source}
                        colorSlug=""
                        sizes={["180px"]}
                    />
                    <Text
                        variant={["headlineMedium", "titleSmall"]}
                        textAlign="center"
                        mt="medium"
                        maxWidth={[null, 507]}
                    >
                        Intro requests, curated by me
                    </Text>
                    <Text
                        variant={["paragraphMedium", "paragraphLarge"]}
                        textAlign="center"
                        mt={["xsmall", "small"]}
                        maxWidth={[null, 720]}
                    >
                        {`Flip the script on your job search by joining ${applylist.group.name}. Take two minutes to fill out your profile and I'll do the work of connecting you directly to top tier companies in my network.`}
                    </Text>
                    <Button
                        variant="primary"
                        size="large"
                        onClick={showModal}
                        mt="large"
                    >
                        Join the collective today!
                    </Button>
                </Box>
                <HiringCompanies
                    group={applylist.group}
                    numCompanies={isMobile ? 1 : undefined}
                />
            </Wrapper>
        </FullPageModal>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`

export default CandidateUpsellModal

import { MODAL_TYPES, TRACK_EVENTS } from "constants/events"
import constate from "constate"
import mixpanel from "mixpanel-browser"
import { useState } from "react"

const SESSION_KEY = "autoPromptSubscribe"

type SubscribeModalProviderProps = {
    slug: string
    isSubscribed: boolean
}

const [SubscribeModalProvider, useSubscribeModal] = constate(
    ({ slug, isSubscribed }: SubscribeModalProviderProps) => {
        const [isVisible, setVisibility] = useState(false)
        const hideSubscribeModal = () => setVisibility(false)
        const showSubscribeModal = (autoPrompt: boolean = false) => {
            const type = isSubscribed
                ? MODAL_TYPES.JOB_BOARD_UNSUBSCRIBE
                : MODAL_TYPES.JOB_BOARD_SUBSCRIBE
            setVisibility(true)
            window?.sessionStorage?.setItem(`${SESSION_KEY}_${slug}`, "true")
            mixpanel.track(TRACK_EVENTS.MODAL_VIEWED, {
                type,
                applylist_slug: slug,
                is_auto_prompt: autoPrompt,
            })
        }

        const autoShowSubscribeModal = () => {
            if (
                !isSubscribed &&
                !window?.sessionStorage?.getItem(`${SESSION_KEY}_${slug}`)
            )
                showSubscribeModal(true)
        }

        return {
            autoShowSubscribeModal,
            isSubscribeModalVisible: isVisible,
            showSubscribeModal,
            hideSubscribeModal,
        }
    }
)

export { SubscribeModalProvider }

export default useSubscribeModal

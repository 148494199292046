import SuccessfulPostModal from "components/Modals/SuccessfulPostModal"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { notify } from "@pallet-hq/hegel"
import { parseBooleans } from "utils/router"
import { useIntercom } from "react-use-intercom"
import { clearPost } from "state/useUpdateOrCreatePost"

type PostResponseProps = {
    slug: string
}

const PostResponse = (props: PostResponseProps) => {
    const { query } = useRouter()
    const { success, uuid, cancel } = parseBooleans(query)
    const intercom = useIntercom()

    useEffect(() => {
        if (cancel) {
            notify.info("Your payment was successfully canceled.")
            intercom.trackEvent("job-post-canceled")
        }
    }, [intercom, cancel])
    useEffect(() => {
        clearPost() // clear reactive variable holding post information
    }, [])
    useEffect(() => {
        if (success) {
            intercom.trackEvent("job-posted")
        }
    }, [intercom, success])

    return success && uuid ? <SuccessfulPostModal slug={props.slug} /> : null
}

export default PostResponse

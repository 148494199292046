import styled from "@emotion/styled"
import { mq, theme } from "@pallet-hq/hegel"

const Sidecard = styled.div`
    display: flex;
    color: inherit;
    text-decoration: inherit;
    flex-direction: column;
    align-items: flex-start;
    ${mq({ width: ["100%", "400px"] })};
    box-sizing: border-box;
    ${mq({ padding: [theme.space.small, theme.space.medium] })};
    padding-bottom: ${theme.space.medium};
    ${mq({ marginBottom: [theme.space.medium, "0px"] })};
    ${mq({
        boxShadow: [
            "0px",
            `0px ${theme.space.xsmall} ${theme.space.small}
    ${theme.colors.black4};`,
        ],
    })};
    border-radius: ${theme.space.xxsmall};
    ${mq({ background: [theme.colors.gray100, theme.colors.white] })};
`

export default Sidecard

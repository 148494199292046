import { gql } from "@apollo/client"
import { Button, notify } from "@pallet-hq/hegel"
import mixpanel from "mixpanel-browser"
import { BUTTON_TYPES, TRACK_EVENTS } from "constants/events"
import PostEditorModal from "features/job-posts/editor/views/PostEditorModal"
import { useCreateOrUpdateListingMutation } from "features/job-posts/editor/hooks/useCreateOrUpdateListingMutation"
import useJobPostEditorModal from "state/useJobPostEditorModal"
import { usePalletRouter } from "utils/hooks/usePalletRouter"
import { PostJobButton_applylist } from "__generated__/PostJobButton_applylist"
import { SubscriptionApprovalStatusEnum } from "__generated__/globalTypes"
import { notifyListingCreateOrUpdateErrors } from "@pallet/features/ListingType/utils/errors"

const PostJobButtonFragment = gql`
    fragment PostJobButton_applylist on ApplylistType {
        id
        slug
        name
        canPost
        canEdit
        customHireLink
        partnership {
            id
            approvalStatusEnum
        }
        partnershipTiers {
            totalCount
        }
    }
`

type PostJobButtonProps = {
    applylist: PostJobButton_applylist
}

const PostJobButton = ({ applylist }: PostJobButtonProps) => {
    const [createListing] = useCreateOrUpdateListingMutation()
    const router = usePalletRouter()
    const { showModal } = useJobPostEditorModal()

    const handleOnModalSubmit = async (postUuid: string) => {
        const resp = await createListing({
            variables: {
                postUuid: postUuid,
                applylistSlug: applylist.slug,
                enabled: true, // create a listing that fills a remaining active job slot if the partnership allows
            },
        })
        const palletName = applylist.canEdit ? "your Pallet" : applylist.name

        if (resp.errors || resp.data?.listingCreateOrUpdate?.userError) {
            return notifyListingCreateOrUpdateErrors(
                resp?.data?.listingCreateOrUpdate?.userError,
                palletName
            )
        }

        if (
            !applylist.canEdit &&
            resp.data?.listingCreateOrUpdate?.listing?.partnership
                ?.approvalStatusEnum !== SubscriptionApprovalStatusEnum.REJECTED
        ) {
            return notify.success(
                `Your job post has been sent for review on ${palletName}.`
            )
        }

        notify.success(`Successfully listed a new job on ${palletName}.`)
    }

    if (!applylist.canPost || !applylist.partnershipTiers?.totalCount)
        return null

    return (
        <>
            <Button
                mt={["small", "0px"]}
                ml={["0px", "large"]}
                width={["100%", "auto"]}
                css={{
                    visibility:
                        applylist.slug === "cardealershipguy"
                            ? "visible"
                            : "hidden",
                }}
                disabled={applylist.slug !== "cardealershipguy"}
                onClick={() => {
                    mixpanel.track(TRACK_EVENTS.BUTTON_CLICKED, {
                        type: BUTTON_TYPES.POST_A_JOB,
                        applylist_slug: applylist.slug,
                    })
                    if (
                        applylist.canEdit ||
                        (applylist.partnership &&
                            applylist.partnership.approvalStatusEnum !==
                                SubscriptionApprovalStatusEnum.REJECTED)
                    ) {
                        showModal()
                    } else {
                        if (applylist.customHireLink)
                            return router.push(applylist.customHireLink)
                        else return router.push("/hire")
                    }
                }}
            >
                Post a job
            </Button>
            <PostEditorModal afterSubmit={handleOnModalSubmit} />
        </>
    )
}

PostJobButton.fragment = PostJobButtonFragment
export default PostJobButton

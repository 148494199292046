import { hasWindow } from "utils/window"

export const postVar = (post: any) => {
    if (hasWindow()) sessionStorage.setItem("job-post", JSON.stringify(post))
}

export const clearPost = () => sessionStorage.removeItem("job-post")

export const usePost = () => {
    if (hasWindow())
        return JSON.parse(sessionStorage.getItem("job-post") || "{}")
    return {}
}

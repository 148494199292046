/*
 * React component to parse links (urls, emails, etc.) in text into clickable links
 * https://github.com/tasti/react-linkify
 */

import styled from "@emotion/styled"
import { Text, TextVariant } from "@pallet-hq/hegel"
import { ReactNode } from "react"
import Linkify from "react-linkify"
import { NewTabLink } from "./NewTabLink"

type LinkParserProps = {
    children: ReactNode
    variant: TextVariant
    onClick?: (e: any) => void
}

export const LinkParser = ({
    variant,
    children,
    ...props
}: LinkParserProps) => (
    <Linkify
        componentDecorator={(href: string, decoratedText: string) => (
            <NewTabLink href={href} {...props}>
                <LinkText as="span" variant={variant} color="blue">
                    {decoratedText}
                </LinkText>
            </NewTabLink>
        )}
    >
        {children}
    </Linkify>
)

const LinkText = styled(Text)`
    text-decoration: underline;
`

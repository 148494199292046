import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import { Modal, theme } from "@pallet-hq/hegel"
import CopyLinkShare from "components/Modals/SharePalletModal/CopyLinkShare"
import { SharePalletModal_applylist } from "__generated__/SharePalletModal_applylist"
import EmbedShare from "./EmbedShare"

const SharePalletModalFragment = gql`
    fragment SharePalletModal_applylist on ApplylistType {
        name
        slug
    }
`

type SharePalletModalProps = {
    isOpen: boolean
    hideModal: () => void
    applylist: SharePalletModal_applylist
}

const SharePalletModal = (props: SharePalletModalProps) => {
    return (
        <StyledModal
            {...props}
            title={`Share ${props.applylist.name}`}
            width="medium"
        >
            <CopyLinkShare />
            <EmbedShare applylist={props.applylist.slug} />
        </StyledModal>
    )
}

const StyledModal = styled(Modal)`
    padding: ${theme.space.medium} ${theme.space.medium} ${theme.space.large}
        ${theme.space.medium};
    box-sizing: border-box;
    > div:not(:last-of-type) {
        margin-bottom: ${theme.space.medium};
    }
`

SharePalletModal.fragment = SharePalletModalFragment

export default SharePalletModal

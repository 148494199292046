import { gql } from "@apollo/client"
import { TalentPreferencesViewFragment } from "@pallet/features/onboardingL1/TalentPreferencesView/fragments"
import { TalentWorkDetailsViewFragment } from "@pallet/features/onboardingL1/TalentWorkDetailsView/fragments"

export const UNTAG_PERSONS = gql`
    mutation UntagPersons(
        $personIds: [String!]
        $searchParams: SearchParametersInputType
        $tagTitles: [String!]!
    ) {
        untagPersons(
            personIds: $personIds
            searchParams: $searchParams
            tagTitles: $tagTitles
        ) {
            ok
        }
    }
`

export const TAG_PERSONS = gql`
    mutation TagPersons(
        $personIds: [String!]
        $searchParams: SearchParametersInputType
        $tagTitles: [String!]!
    ) {
        tagPersons(
            personIds: $personIds
            searchParams: $searchParams
            tagTitles: $tagTitles
        ) {
            ok
        }
    }
`

export const ENROLL_PERSONS = gql`
    mutation EnrollPersons(
        $campaignId: String!
        $personIds: [String!]
        $searchParams: SearchParametersInputType
        $palletId: String
    ) {
        enrollPersons(
            campaignId: $campaignId
            personIds: $personIds
            searchParams: $searchParams
            palletId: $palletId
        ) {
            campaign {
                id
                title
            }
        }
    }
`

export const SOURCE_PERSONS = gql`
    mutation SourcePersons(
        $postId: String!
        $personIds: [String!]!
        $palletId: String
    ) {
        sourcePersons(
            postId: $postId
            personIds: $personIds
            palletId: $palletId
        ) {
            post {
                id
            }
        }
    }
`

export const RECORD_INTERACTIONS = gql`
    mutation RecordInteractions(
        $teamId: String!
        $personIds: [String!]!
        $interaction: InteractionTypeEnum!
        $origin: CandidateFeedEnum!
        $palletId: String
        $projectId: String
    ) {
        recordInteractions(
            teamId: $teamId
            personIds: $personIds
            interaction: $interaction
            origin: $origin
            palletId: $palletId
            projectId: $projectId
        ) {
            ok
        }
    }
`

export const CREATE_INTROS = gql`
    mutation CreateIntros(
        $teamId: String!
        $personIds: [String!]!
        $message: String!
        $origin: CandidateFeedEnum!
    ) {
        createIntros(
            teamId: $teamId
            personIds: $personIds
            message: $message
            origin: $origin
        ) {
            ok
        }
    }
`

export const UNSUBSCRIBE_PERSON_FROM_RECRUITING_CAMPAIGNS = gql`
    mutation UnsubscribePersonFromRecruitingCampaigns(
        $unsubscribeToken: String!
        $nMonths: Int!
    ) {
        unsubscribePersonFromRecruitingCampaigns(
            unsubscribeToken: $unsubscribeToken
            nMonths: $nMonths
        ) {
            success
        }
    }
`

export const SUBSCRIBE_TO_PALLET = gql`
    mutation SubscribeToPallet($slug: String!) {
        toggleNotifyOnUpdate(slug: $slug) {
            applylist {
                id
                name
                slug
                notifyOnUpdate
                isFollowed
            }
        }
    }
`

export const UNSUBSCRIBE_FROM_PALLET = gql`
    mutation UnsubscribeFromPallet($slug: String!) {
        toggleNotifyOnUpdate(slug: $slug) {
            applylist {
                id
                name
                slug
                notifyOnUpdate
                isFollowed
            }
        }
    }
`

export const SUBMIT_PREFERENCES_FORM = gql`
    mutation SubmitPreferencesForm(
        $user: String!
        $linkedinUrl: String
        $currentCity: Int
        $selectedCities: [Int!]
        $isOpenToRemote: Boolean
        $selectedIndustries: [String!]
        $workStatus: String
    ) {
        updateJobSeeker(
            userSlug: $user
            linkedinUrl: $linkedinUrl
            currentCity: $currentCity
            selectedCities: $selectedCities
            isOpenToRemote: $isOpenToRemote
            selectedIndustries: $selectedIndustries
            workStatus: $workStatus
        ) {
            user {
                id
                ...TalentPreferencesView_me
            }
        }
    }
    ${TalentPreferencesViewFragment}
`

export const SUBMIT_WORK_DETAILS_FORM = gql`
    mutation SubmitWorkDetailsForm(
        $user: String!
        $currentJobType: String
        $yearsOfExperience: Int
        $hasNoWorkExperience: Boolean
        $currentTitle: String
        $recentEmployer: String
    ) {
        updateJobSeeker(
            userSlug: $user
            currentJobType: $currentJobType
            yearsOfExperience: $yearsOfExperience
            hasNoWorkExperience: $hasNoWorkExperience
            currentTitle: $currentTitle
            recentEmployer: $recentEmployer
        ) {
            user {
                id
                ...TalentWorkDetailsView_me
            }
        }
    }
    ${TalentWorkDetailsViewFragment}
`

export const REQUEST_STORAGE_UPLOAD_URL = gql`
    mutation RequestStorageUploadUrl($pallet: String!) {
        requestStorageUploadUrl(pallet: $pallet) {
            url
            blob
            errors {
                message
            }
        }
    }
`

export const UPLOAD_PERSON_SOURCE_FILE = gql`
    mutation UploadPersonSourceFile(
        $slug: String!
        $file: Upload
        $fileName: String
        $fileLocation: String
    ) {
        uploadPersonSourceFile(
            pallet: $slug
            uploaded: $file
            fileName: $fileName
            fileLocation: $fileLocation
        ) {
            success
            errors {
                message
            }
            source {
                id
                kind
                name
                status
                count
            }
        }
    }
`

export const CREATE_SUBSTACK_SOURCE = gql`
    mutation CreateSubstackSource(
        $pallet: String!
        $substackSlug: String!
        $substackUrl: String!
    ) {
        createSubstackSource(
            pallet: $pallet
            substackSlug: $substackSlug
            substackUrl: $substackUrl
        ) {
            success
            errors {
                message
            }
            source {
                id
                kind
                name
                status
                count
            }
        }
    }
`

export const CREATE_BEEHIIV_SOURCE = gql`
    mutation CreateBeehiivSource(
        $pallet: String!
        $publicationId: String!
        $apiKey: String!
    ) {
        createBeehiivSource(
            pallet: $pallet
            publicationId: $publicationId
            apiKey: $apiKey
        ) {
            success
            errors {
                message
            }
            source {
                id
                kind
                name
                status
                count
            }
        }
    }
`

export const STORE_UNCONFIRMED_DATA = gql`
    mutation StoreUnconfirmedData(
        $email: String!
        $pallet: String!
        $path: String!
        $firstName: String
        $lastName: String
        $currentCity: Int
        $linkedinUrl: String
        $postId: String
        $teamToLike: String
        $selectedCities: [Int!]
        $selectedJobTypes: [String!]
        $selectedCompanySizes: [String!]
        $preferencesSummary: String
        $dispatchId: String
    ) {
        storeUnconfirmedData(
            email: $email
            pallet: $pallet
            path: $path
            firstName: $firstName
            lastName: $lastName
            currentCity: $currentCity
            linkedinUrl: $linkedinUrl
            postId: $postId
            teamToLike: $teamToLike
            selectedCities: $selectedCities
            selectedJobTypes: $selectedJobTypes
            selectedCompanySizes: $selectedCompanySizes
            preferencesSummary: $preferencesSummary
            dispatchId: $dispatchId
        ) {
            ok
        }
    }
`

export const SUBMIT_JOB_APPLICATION = gql`
    mutation SubmitJobApplication(
        $token: String!
        $postId: String!
        $palletId: String
        $profileData: ProfileDataInputType
        $dispatchId: String
    ) {
        submitJobApplication(
            token: $token
            postId: $postId
            palletId: $palletId
            profileData: $profileData
            dispatchId: $dispatchId
        ) {
            applicationId
        }
    }
`

export const CONFIRM_UNCONFIRMED_DATA = gql`
    mutation ConfirmUnconfirmedData($dataId: String!) {
        confirmUnconfirmedData(dataId: $dataId) {
            ok
            token
        }
    }
`

export const UPDATE_JOB_SEEKER_WITH_TOKEN_MUTATION = gql`
    mutation UpdateJobSeekerWithToken(
        $token: String!
        $firstName: String
        $lastName: String
        $currentCity: Int
        $linkedinUrl: String
        $selectedCities: [Int!]
        $selectedJobTypes: [String!]
        $selectedCompanySizes: [String!]
        $preferencesSummary: String
        $dispatchId: String
    ) {
        updateJobSeekerWithToken(
            token: $token
            firstName: $firstName
            lastName: $lastName
            currentCity: $currentCity
            linkedinUrl: $linkedinUrl
            selectedCities: $selectedCities
            selectedJobTypes: $selectedJobTypes
            selectedCompanySizes: $selectedCompanySizes
            preferencesSummary: $preferencesSummary
            dispatchId: $dispatchId
        ) {
            ok
        }
    }
`

export const EXPORT_PERSONS = gql`
    mutation ExportPersons($exportArgs: ExportParametersInputType!) {
        exportBigIndexPersons(exportArgs: $exportArgs) {
            exportId
            error {
                message
            }
        }
    }
`

export const CREATE_SEGMENT = gql`
    mutation CreateSegment(
        $name: String!
        $personIds: [String!]
        $searchParams: SearchParametersInputType
    ) {
        createSegment(
            name: $name
            personIds: $personIds
            searchParams: $searchParams
        ) {
            segment {
                id
                name
                count
            }
            error {
                message
            }
        }
    }
`

export const EDIT_PERSONS_IN_SEGMENT = gql`
    mutation EditPersonsInSegment(
        $segmentId: String!
        $personIds: [String!]
        $searchParams: SearchParametersInputType
        $add: Boolean!
    ) {
        editPersonsInSegment(
            segmentId: $segmentId
            personIds: $personIds
            searchParams: $searchParams
            add: $add
        ) {
            segment {
                id
                name
                count
            }
            error {
                message
            }
        }
    }
`

export const ENRICH_PERSONS = gql`
    mutation EnrichPersons($personIds: [String!]!) {
        enrichPersons(personIds: $personIds) {
            ok
            error {
                message
            }
        }
    }
`

export const GENERATE_MERGE_LINK_TOKEN_MUTATION = gql`
    mutation GenerateMergeLinkTokenMutation($team: String!) {
        generateMergeLinkToken(team: $team) {
            linkToken
            error {
                message
            }
        }
    }
`

export const ACTIVATE_ATS_INTEGRATION_MUTATION = gql`
    mutation ActivateAtsIntegrationMutation($publicToken: String!) {
        activateAtsIntegration(publicToken: $publicToken) {
            integration {
                id
                name
                isActive
                logo
                status
                source
                candidateTags
            }
            error {
                message
            }
        }
    }
`

export const DEACTIVATE_ATS_INTEGRATION_MUTATION = gql`
    mutation DeactivateAtsIntegrationMutation($integrationId: String!) {
        deactivateAtsIntegration(integrationId: $integrationId) {
            integration {
                id
                isActive
            }
            error {
                message
            }
        }
    }
`

export const REFRESH_ATS_INTEGRATION_MUTATION = gql`
    mutation RefreshAtsIntegrationMutation($integrationId: String!) {
        refreshAtsIntegration(integrationId: $integrationId) {
            ok
            error {
                message
            }
        }
    }
`

export const UPDATE_ATS_INTEGRATION_MUTATION = gql`
    mutation UpdateAtsIntegrationMutation(
        $integrationId: String!
        $source: String
        $candidateTags: [String!]
    ) {
        updateAtsIntegration(
            integrationId: $integrationId
            source: $source
            candidateTags: $candidateTags
        ) {
            integration {
                id
                name
                isActive
                logo
                color
                status
                source
                candidateTags
            }
            error {
                message
            }
        }
    }
`

import styled from "@emotion/styled"
import { mq, Text, theme } from "@pallet-hq/hegel"
import PostJobButton from "components/Buttons/PostJob"
import { PostJobButtonLoader } from "components/Buttons/PostJob/Loader"
import { LinkParser } from "components/Link/LinkParser"
import SharePalletModal from "components/Modals/SharePalletModal"
import { useState } from "react"
import ContentLoader from "react-content-loader"
import { FiShare2 } from "react-icons/fi"
import { ListHeader_applylist } from "__generated__/ListHeader_applylist"
import { ListInfoHeaderFragment } from "./fragment"

export type ListInfoHeaderProps = {
    applylist?: ListHeader_applylist
}

const ListInfoHeader = (props: ListInfoHeaderProps) => {
    const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false)

    return (
        <Wrapper>
            <FullWidthDiv>
                <MainTitleDiv>
                    <Text variant={["headlineMedium", "headlineLarge"]}>
                        {props.applylist ? (
                            props.applylist.name
                        ) : (
                            <ContentLoader
                                speed={2}
                                width="200px"
                                height="32px"
                                backgroundColor={theme.colors.gray300}
                                foregroundOpacity={1}
                                stroke="none"
                                display="block"
                            >
                                <rect
                                    x="0"
                                    y="0"
                                    rx="8"
                                    ry="8"
                                    width="200px"
                                    height="32px"
                                />
                            </ContentLoader>
                        )}
                    </Text>
                    <IconRow>
                        {props.applylist?.canView && (
                            <>
                                <IconDiv>
                                    <SharePalletModal
                                        isOpen={isShareModalOpen}
                                        hideModal={() =>
                                            setIsShareModalOpen(false)
                                        }
                                        applylist={props.applylist}
                                    />
                                    <FiShare2
                                        size={24}
                                        onClick={() =>
                                            setIsShareModalOpen(true)
                                        }
                                    />
                                </IconDiv>
                            </>
                        )}
                    </IconRow>
                </MainTitleDiv>
                <Text variant="paragraphLarge" mt="xxsmall">
                    {props.applylist ? (
                        <LinkParser variant="paragraphLarge">
                            {props.applylist?.subheading || "..."}
                        </LinkParser>
                    ) : (
                        <ContentLoader
                            speed={2}
                            width="300px"
                            height="24px"
                            backgroundColor={theme.colors.gray300}
                            foregroundOpacity={1}
                            stroke="none"
                            display="block"
                        >
                            <rect
                                x="0"
                                y="0"
                                rx="8"
                                ry="8"
                                width="300px"
                                height="24px"
                            />
                        </ContentLoader>
                    )}
                </Text>
            </FullWidthDiv>
            {props.applylist ? (
                <PostJobButton applylist={props.applylist} />
            ) : (
                <PostJobButtonLoader />
            )}
        </Wrapper>
    )
}

ListInfoHeader.fragment = ListInfoHeaderFragment

const IconRow = styled.div`
    display: flex;
    cursor: pointer;
`

const Wrapper = styled.div`
    display: flex;
    ${mq({ flexDirection: ["column", "row", "row"] })}
    align-items: center;
`

const FullWidthDiv = styled.div`
    width: 100%;
`

const IconDiv = styled.div`
    display: flex;
    margin-left: ${theme.space.medium};
`

const MainTitleDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${mq({ justifyContent: ["space-between;", "unset"] })}
`

export default ListInfoHeader

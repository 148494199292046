import { gql } from "@apollo/client"
import { theme } from "@pallet-hq/hegel"
import { CompanyImagePreview_company } from "__generated__/CompanyImagePreview_company"
import { ListImagePreview_applylist } from "__generated__/ListImagePreview_applylist"
import Image from "."

const CompanyImagePreviewFragment = gql`
    fragment CompanyImagePreview_company on CompanyType {
        slug
        image
    }
`
const ListImagePreviewFragment = gql`
    fragment ListImagePreview_applylist on ApplylistType {
        id
        slug
        logo {
            source
        }
    }
`

type Props = (CompanyImagePreview_company | ListImagePreview_applylist) & {
    height?: number
    width?: number
}

const CircleImagePreview = (props: Props) => {
    return (
        <Image
            width={props.width || 60}
            height={props.height || 60}
            borderRadius="50%"
            border={`1px solid ${theme.colors.gray400}`}
            src={
                props.__typename === "CompanyType"
                    ? props.image!
                    : props.logo?.source
            }
            alt="Preview"
            colorSlug={props.slug}
            imgixParams={{
                fit: "fill",
                fill: "solid",
                "fill-color": "white",
            }}
        />
    )
}

CircleImagePreview.companyFragment = CompanyImagePreviewFragment
CircleImagePreview.applylistFragment = ListImagePreviewFragment

export default CircleImagePreview

import styled from "@emotion/styled"
import { mq, theme } from "@pallet-hq/hegel"
import { ListInfo_applylist } from "__generated__/ListInfo_applylist"
import ListInfoHeader from "./ListInfoHeader"
import { ListInfoFragment } from "./fragment"

export type ListInfoProps = {
    applylist?: ListInfo_applylist
}

const ListInfo = (props: ListInfoProps) => {
    return (
        <ListInfoDiv>
            <ListInfoHeader applylist={props.applylist}></ListInfoHeader>
        </ListInfoDiv>
    )
}

ListInfo.fragment = ListInfoFragment

const ListInfoDiv = styled.div`
    ${mq({ paddingTop: [theme.space.medium, theme.space.medium] })}
    ${mq({ paddingBottom: [theme.space.medium, "0px"] })}
  ${mq({ paddingRight: [theme.space.small, "0px"] })}
`

export default ListInfo

import React from "react"
import { theme } from "@pallet-hq/hegel"
import ContentLoader from "react-content-loader"
import { useScreenSize } from "utils/hooks/useSize"

export const PalletThumbnailImageLoader = (props: { sizes: string[] }) => {
    const { isMobile } = useScreenSize()
    return (
        <ContentLoader
            speed={2}
            width={isMobile ? props.sizes[0] : props.sizes[1]}
            height={isMobile ? props.sizes[0] : props.sizes[1]}
            backgroundColor={theme.colors.gray500}
            foregroundOpacity={1}
            stroke="none"
            display="block"
        >
            <rect
                x="0"
                y="0"
                rx="16"
                ry="16"
                width={isMobile ? props.sizes[0] : props.sizes[1]}
                height={isMobile ? props.sizes[0] : props.sizes[1]}
            />
        </ContentLoader>
    )
}

import { gql } from "@apollo/client"
import { GroupHiringCompaniesFragment } from "features/companies/featured/display/HiringCompanies/fragment"

export const CandidateUpsellFragment = gql`
    fragment CandidateUpsellFragment_applylist on ApplylistType {
        id
        logo {
            source
        }
        group {
            id
            description
            ...GroupHiringCompaniesFragment_group
        }
    }
    ${GroupHiringCompaniesFragment}
`

import styled from "@emotion/styled"
import { BaseCheckboxField, Button, notify, theme } from "@pallet-hq/hegel"
import { VerticallyCenteredRow } from "components/atoms"
import { useEffect, useState } from "react"
import { useHasMounted } from "utils/hooks"
import { SubSectionHeader, TextBox } from "./shared"

type EmbedShareProps = {
    applylist: string
}

const EmbedShare = (props: EmbedShareProps) => {
    const [showCode, setShowCode] = useState<boolean>(false)
    const hasMounted = useHasMounted()
    const getEmbedCode = () =>
        hasMounted
            ? `<iframe src='${window.location.origin}/embed/list/${props.applylist}' height='380' width='100%' frameBorder='0'></iframe>`
            : ""

    useEffect(() => {
        if (hasMounted) {
            document.body.style.overflow = "hidden"
        }
        return () => {
            document.body.style.overflow = "unset"
        }
    }, [hasMounted])

    return (
        <div>
            <SubSectionHeader variant="uiLarge600" mt={1}>
                Share via embed
            </SubSectionHeader>
            {hasMounted && (
                <IFrame
                    src={`${window.location.protocol}//${window.location.host}/embed/list/${props.applylist}`}
                />
            )}
            {showCode && <CodeBox>{getEmbedCode()}</CodeBox>}
            <RightRow>
                <CheckboxContainer>
                    <BaseCheckboxField
                        name=""
                        label="Show Code"
                        isSelected={showCode}
                        onChange={() => setShowCode(v => !v)}
                    />
                </CheckboxContainer>
                <Button
                    variant="secondary"
                    size="small"
                    onClick={() => {
                        navigator.clipboard
                            .writeText(getEmbedCode())
                            .then(() => notify.success("Code copied!"))
                    }}
                >
                    Copy
                </Button>
            </RightRow>
        </div>
    )
}

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${theme.space.xsmall};
`

const CodeBox = styled(TextBox)`
    margin-bottom: ${theme.space.small};
`

const RightRow = styled(VerticallyCenteredRow)`
    justify-content: flex-end;
    gap: ${theme.space.medium};
`

const IFrame = styled.iframe`
    height: 380px;
    width: 100%;
    border-radius: 16px;
    border: 1px solid ${theme.colors.gray200};
    margin-bottom: ${theme.space.small};
`

export default EmbedShare

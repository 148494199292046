import { gql } from "@apollo/client"
import SharePalletModal from "components/Modals/SharePalletModal"
import PostJobButton from "components/Buttons/PostJob"

export const ListInfoHeaderFragment = gql`
    fragment ListHeader_applylist on ApplylistType {
        id
        name
        subheading
        canEdit
        canView
        ...SharePalletModal_applylist
        ...PostJobButton_applylist
    }
    ${SharePalletModal.fragment}
    ${PostJobButton.fragment}
`

export const ListInfoFragment = gql`
    fragment ListInfo_applylist on ApplylistType {
        id
        ...ListHeader_applylist
    }
    ${ListInfoHeaderFragment}
`

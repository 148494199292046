import React from "react"
import { theme } from "@pallet-hq/hegel"
import ContentLoader from "react-content-loader"
import { useScreenSize } from "utils/hooks/useSize"

export const PalletImageHeaderLoader = (props: {
    desktopHeight: string
    mobileHeight: string
}) => {
    const { isMobile } = useScreenSize()
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={isMobile ? props.mobileHeight : props.desktopHeight}
            backgroundColor={theme.colors.gray300}
            foregroundOpacity={0.25}
            stroke="none"
            display="block"
        >
            <rect
                x="0"
                y="0"
                width="100%"
                height={isMobile ? props.mobileHeight : props.desktopHeight}
            />
        </ContentLoader>
    )
}

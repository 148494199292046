import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import { Text, theme } from "@pallet-hq/hegel"
import Image from "components/Image"
import ContentLoader from "react-content-loader"
import { CuratorPreview_applylist } from "__generated__/CuratorPreview_applylist"
import CuratorSocials from "./CuratorSocials"

const CuratorPreviewFragment = gql`
    fragment CuratorPreview_applylist on ApplylistType {
        curatorName
        curatorBio
        curatorAvatar {
            source
        }
        ...CuratorSocials_applylist
    }
    ${CuratorSocials.fragment}
`

export type CuratorPreviewProps = {
    applylist?: CuratorPreview_applylist
}

const CuratorPreview = (props: CuratorPreviewProps) => {
    return (
        <>
            <CuratorPreviewDiv>
                <ImageDiv>
                    {props.applylist ? (
                        <CuratorImage
                            width={60}
                            height={60}
                            borderRadius="100%"
                            src={props.applylist?.curatorAvatar.source}
                            colorSlug=""
                        />
                    ) : (
                        <ContentLoader
                            speed={2}
                            width="60px"
                            height="60px"
                            backgroundColor={theme.colors.gray300}
                            foregroundOpacity={0.25}
                            stroke="none"
                            display="block"
                        >
                            <rect
                                x="0"
                                y="0"
                                rx="30px"
                                ry="30px"
                                width="60px"
                                height="60px"
                            />
                        </ContentLoader>
                    )}
                </ImageDiv>
                <CuratorInfoDiv>
                    <div>
                        <Text as="span" variant="uiTiny500">
                            {props.applylist ? "Curated by " : ""}
                        </Text>
                        <Text as="span" variant="uiSmall600">
                            {props.applylist?.curatorName}
                        </Text>
                    </div>
                    <InfoOverviewDiv>
                        <Text
                            variant="uiTiny500"
                            style={{ wordBreak: "break-word" }}
                        >
                            {props.applylist?.curatorBio}
                        </Text>
                    </InfoOverviewDiv>
                </CuratorInfoDiv>
            </CuratorPreviewDiv>
            <CuratorSocials {...props} />
        </>
    )
}

CuratorPreview.fragment = CuratorPreviewFragment

const CuratorPreviewDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding: ${theme.space.small};
    margin: calc(-1 * ${theme.space.small});
    margin-bottom: ${theme.space.xxsmall};
    border-radius: 2px;
    width: 100%;
`

const CuratorImage = styled(Image)`
    object-fit: cover;
`

const CuratorInfoDiv = styled.div`
    display: flex;
    flex-direction: column;
`

const InfoOverviewDiv = styled.div`
    margin-top: ${theme.space.xxsmall};
`

const ImageDiv = styled.div`
    margin-right: ${theme.space.small};
    display: flex;
    align-items: center;
`

export default CuratorPreview
